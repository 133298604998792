import React from 'react';
import logo from './assets/logoanimated.gif';
import spiralleft from './assets/spiralleft.webp';  // Adjust the path as needed
import spiralright from './assets/spiralright.webp'; // Adjust the path as needed

import './HeaderStyle.css';

const Header = () => {
  return (
    <header className="relative bg-tmbblack text-white flex flex-col items-center justify-center overflow-hidden h-screen">
      <div className="absolute inset-0 flex justify-between">
      <img src={spiralleft} className="absolute top-0 left-0  pointer-events-none" style={{ transform: 'translate(-40%, -20%)' }} />

      <img src={spiralright} className="absolute bottom-0 right-0  pointer-events-none" style={{ transform: 'translate(30%, 40%)' }} />

      </div>

      <div className="absolute top-1/4 left-0 w-full overflow-hidden whitespace-nowrap">
        <h1 className="inline-block text-[10vw] font-bold text-white marquee">
          The Future of Mixing &nbsp; The Future of Mixing &nbsp; The Future of Mixing &nbsp; The Future of Mixing
        </h1>
      </div>

      <div className="relative z-10 flex flex-col items-center">
        <img
          src={logo}
          alt="Header Image"
          className="w-[680px] h-[380px] mb-4" // Adjust width and height
        />
        <p className="text-center text-md mb-4 font-kumbh">
          The first AI Mixing & Mastering service that can take any <br />
          song, and deliver a better sounding version in minutes!
        </p>
        <button className="bg-tmbdarkred text-white font-bold py-2 px-4 rounded-full hover:bg-red-700">
          Join Now
        </button>
      </div>
    </header>
  );
};

export default Header;
