// src/components/Library.js
import React, { useEffect, useState } from 'react';
import Navbar from './navbar';
import UploadBanner from './uploadbanner';
import Footer from './footer';

import { collection, query, where, getDocs } from 'firebase/firestore';
import { db, auth } from '../firebase'; // Import auth as well
import { onAuthStateChanged } from 'firebase/auth';

const Library = () => {
  const [uploads, setUploads] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchUploads = async () => {
      if (user) {
        try {
          const uploadsQuery = query(collection(db, 'uploads'), where('userId', '==', user.uid));
          const querySnapshot = await getDocs(uploadsQuery);
          const uploadData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          console.log(uploadData);
          setUploads(uploadData);
        } catch (error) {
          console.error('Error fetching uploads: ', error);
        }
      }
    };

    fetchUploads();
  }, [user]);

  return (
    <div>
      <Navbar />
      <div className="min-h-screen bg-tmbblack text-white py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-5xl font-bold text-center mb-8">Library</h1>
          <p className="text-center text-xl mb-16">All of your Uploads & Mixes in one place.</p>

          <div className="flex justify-between items-center mb-8">
            <button className="bg-gray-800 text-white py-2 px-4 rounded-lg">Sort by</button>
            <div className="relative">
              <input
                type="text"
                placeholder="Search library"
                className="bg-gray-700 text-white py-2 px-4 rounded-full"
              />
              <button className="absolute right-2 top-2 text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 16a8 8 0 100-16 8 8 0 000 16zm14-2l-4.35-4.35" />
                </svg>
              </button>
            </div>
          </div>

          <div>
            <h2 className="text-3xl font-bold mb-6">Uploads</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mb-16">
              {uploads.map((upload) => (
                <LibraryItem key={upload.id} title={upload.title} status={upload.status} duration={upload.duration} />
              ))}
            </div>
          </div>

          <div>
            <h2 className="text-3xl font-bold mb-6 text-red-500">Mixes</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {/* Repeat similar blocks for other mixes */}
            </div>
          </div>
        </div>
      </div>

      <UploadBanner />
      <Footer />
    </div>
  );
};

const LibraryItem = ({ title, status, duration }) => {
  return (
    <div className="bg-gray-800 p-4 rounded-lg border-2 border-red-600">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-semibold">{title}</h3>
        <p className="text-gray-400 text-sm">MP3</p>
      </div>
      <p className={`text-${status === 'UNMIXED' ? 'gray-400' : 'green-400'} text-sm mt-2`}>{status}</p>
      <div className="relative mt-4">
        <div className="h-1 bg-gray-600 rounded-full">
          <div className="h-1 bg-red-600 rounded-full" style={{ width: '50%' }}></div>
        </div>
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 bg-red-600 h-4 w-4 rounded-full border-2 border-gray-800"></div>
      </div>
      <div className="flex justify-between items-center text-gray-400 text-sm mt-2">
        <span>00:00</span>
        <div className="flex items-center">
          <button className="bg-gray-700 p-2 rounded-full mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 9V5l7 7-7 7v-4H5v-6h5z" />
            </svg>
          </button>
          <button className="bg-gray-700 p-2 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.752 11.168l-4.586-4.586m4.586 4.586H6m8.752 0H12m2.752 4.168m0 0L12 20" />
            </svg>
          </button>
        </div>
        <span>{duration}</span>
      </div>
    </div>
  );
};

export default Library;
